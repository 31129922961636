// Here you can add other styles
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@500&family=Squada+One&family=Jolly+Lodger&family=Zen+Dots&display=swap");

.classbglogintop {
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
	height:100%;
	transform: rotate(180deg);
	background-color: transparent;
	background-position: bottom right;
	background-repeat: repeat-x;
}
.classbgtransparent {
	background-color: transparent !important;
}
.classbgwhite {
	background-color: white;
}
.classbgdisabled {
	background-color: #F7F7F7 !important;
}
.classheadermain {
	background-image: linear-gradient(#d7432977,#DCDCDC33);/**/
	/*background: white url(../img/bg_header_top.png) top left repeat-x;/**/
}
.classtitle,.classtitlelogin,.classtitle a {
	color: #A1321F !important;/**/
	font-family: "Squada One", cursive;
	font-size: 150%;
	font-weight: bolder !important;
}
.classtitlelogin {
	color: #d74329 !important;/**/
	font-size: 200%;
}
.classtitlesidebar {
	font-size: 180% !important;
	color: inherit !important;
	font-weight: normal;
	text-shadow: 0 !important;
}
.classtitlesub {
	font-size: 140%;
	color: gray;
}
.classposmidcenter {
	top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1060;
}
.classfontsmaller {
	font-size: smaller !important;
}
.classtombol {
	font-family: "Zen Dots", cursive;
	color: white !important;
	border-radius: 10px !important;
	background-image: linear-gradient(#DCDCDC,#d74329);
	border-style:none !important;
}
.classtombol:disabled {
	color: rgba(black,0.6) !important;
	background-image: linear-gradient(#f0f0f0,gray);
}
.classfontitalic {
	font-style: italic;
}
.classfontlarger {
	font-size: larger;
}
.classcolorgreen {
	color:green;
}
.classcardbg { background-color:#ffffff99; }
.classcursorpointer {
	cursor: pointer
}
.classpetunjuk {
	color: Maroon;
	font-style:italic;
	font-size:80%;
	padding-left:15px;
}
.classpetunjuk2 {
	color: Maroon;
	font-style:italic;
	padding-left:15px;
}
.classborderr1 {
	border:15px solid red;
	border-color: rgba(silver,0.3);
	border-radius: 15px;
}
.classToTop {
	cursor:pointer;
	position:fixed;
	min-width: 30px;
	z-index:1030;
	bottom: 35px;
	right:20px;
	opacity:1;
	transition: width 0.1s, height 0.1s, opacity 0.1s 0.1s;
}
.classzIndex1001 {
	z-index:1001 !important;
}
.classzIndex1091 {
	z-index:10910 !important;
}
.classbordertmp {
	border: 1px solid red !important;
}
.classbordertop {
	border-width:0;
	border-top: 1px solid #DCDCDC;
}
.classborderbottom{
	border-width:0;
	border-bottom: 1px solid #DCDCDC;
}
.classborderbottomdotted{
	border-width:0;
	border-bottom: 1px dotted #DCDCDC;
}
.classbordertopdobel {
	border-width:0;
	border-top: 4px double silver;
}
.classbordersquare {
	border: 1px dashed #DCDCDC;
}
.classborderbox {
	border: 1px dashed #DCDCDC;
	border-radius: 5px;
}
.classborderdotted {
	border-style: dotted;
}
.classborderdashed {
	border-style: dashed;
}
.classtabelfakethead {
	font-size:larger;
	font-weight: bolder;
	background-color: silver;
	color:white;
}
.classtabeltfoot {
	background-color: #EDFCF1;
	border-top: 1px solid #DCDCDC;
}
.classtabeltfootlaporan {
	background-color: #DCDCDC;
	border-top: 1px solid silver;
	font-size: larger;
	color: blue;
}
.classikonborder {
	border: 1px solid white;
	border-radius: 8px;
	background-color: rgba(#ffffff,0.3);
}
.classrowselect {
	background-color: rgba(#FCF3CF, 0.5) !important;
}
.classautocomplete {
	position: absolute;
	width: 95%;
	z-index: 1000;
	background-color: white;
}
.classautocompletelist {
	cursor: pointer;
	border-bottom: 1px dashed #DCDCDC;/**/
}

.classradiobutton {
	border:1px solid #f0f0f0;
	border-radius: 5px !important;
	padding: 7px !important;
	padding-left:35px !important;
	margin-bottom: 5px;
	background-color: #F6F7F8;
}
.classradiobutton:hover {
	background-color: #f0f0f0;
}
.classradiobutton label {
	display: block;
	cursor: pointer;
	/*border: 1px solid red;/**/
}
.classradiobutton input[radio]:checked {
	background-color: blue !important;
}
.classactionfoto:hover {
    background: transparent url(../img/icon_close.png) center center no-repeat;
    background-size: 25px 25px;
	background-color: rgba(gray,0.85) !important;
}
.classikontabel {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    margin-right: 3px;
}
.classikon {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin-right: 3px;
}
.classikoncoreui {
	border:1px solid rgba(#5788A9,0.6);
	border-radius: 5px;
	background-color: rgba(#5788A9,0.2);
	padding: 3px;
	margin: auto 3px;
	color: #5788A9;
	vertical-align:middle;
}
.classikonavatar {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    background-color: #DCDCDC !important;
    border: 1px dotted white;
}
.classikonlogo {
	background: transparent url(../img/icon_logo.png) center center no-repeat;
	background-size: cover;
}
.classikonlogowhite {
	background: transparent url(../img/icon_logowhite.png) center center no-repeat;
	background-size: cover;
}
.classikondashboard{
    background: transparent url(../img/icon_dashboard.png) center center no-repeat;
    background-size: cover;
}
.classikonfamily {
    background: transparent url(../img/icon_family.png) center center no-repeat;
    background-size: cover;
}
.classikonedit{
    background: transparent url(../img/icon_edit.png) center center no-repeat;
    background-size: cover;
}
.classikonhapus{
    background: transparent url(../img/icon_hapus.png) center center no-repeat;
    background-size: cover;
}
.classikonsetrole{
    background: transparent url(../img/menus_setrole.png) center center no-repeat;
    background-size: cover;
}
.classikononline{
    background: transparent url(../img/icon_online.png) center center no-repeat;
    background-size: cover;
}
.classikonoffline{
    background: transparent url(../img/icon_offline.png) center center no-repeat;
    background-size: cover;
}
.classikontambah {
    background: transparent url(../img/icon_tambah.png) center center no-repeat;
    background-size: cover;
}
.classikontambah2 {
    background: transparent url(../img/icon_tambah2.png) center center no-repeat;
    background-size: cover;
}
.classikontambah3 {
    background: transparent url(../img/icon_tambah3.png) center center no-repeat;
    background-size: cover;
}
.classikontambah4 {
    background: transparent url(../img/icon_tambah4.png) center center no-repeat;
    background-size: cover;
}
.classikontambahitem {
    background: transparent url(../img/icon_tambahitem.png) center center no-repeat;
    background-size: cover;
}
.classikonreset {
    background: transparent url(../img/icon_reset.png) center center no-repeat;
    background-size: cover;
}
.classikoninfo {
	background: transparent url(../img/icon_info.png) center center no-repeat;
	background-size: cover;
}
.classikonchecked {
	background: transparent url(../img/icon_checked.png) center center no-repeat;
	background-size: cover;
}
.classikoncari {
	background: transparent url(../img/icon_cari.png) center center no-repeat;
	background-size: cover;
}
.classikondetil {
	background: transparent url(../img/icon_detil.png) center center no-repeat;
	background-size: cover;
}
.classikontreatment {
	background: transparent url(../img/icon_treatment.png) center center no-repeat;
	background-size: cover;
}
.classikonloading {
	background: transparent url(../img/icon_loading.gif) center center no-repeat;
	background-size: cover;
    width: 18px;
    height: 18px;
}
.classikonbayar {
	background: transparent url(../img/icon_bayar.png) center center no-repeat;
	background-size: cover;
}
.classikonhistoribayar {
	background: transparent url(../img/icon_historibayar.png) center center no-repeat;
	background-size: cover;
}
.classikonhistori {
	background: transparent url(../img/icon_histori.png) center center no-repeat;
	background-size: cover;
}
.classikonopname{
	background: transparent url(../img/icon_stokopname.png) center center no-repeat;
	background-size: cover;
}
.classikontambahstok{
	background: transparent url(../img/icon_stoktambah.png) center center no-repeat;
	background-size: cover;
}
.classikonkurangstok{
	background: transparent url(../img/icon_stokkurang.png) center center no-repeat;
	background-size: cover;
}
.classikoncetak {
	background: transparent url(../img/icon_cetak.png) center center no-repeat;
	background-size: cover;
}
.classikonexcel {
	background: transparent url(../img/icon_excel.png) center center no-repeat;
	background-size: cover;
}
.classikonup {
	background: transparent url(../img/icon_up.png) center center no-repeat;
	background-size: cover;
}
.classikondown {
	background: transparent url(../img/icon_down.png) center center no-repeat;
	background-size: cover;
}
.classikonjual {
	background: transparent url(../img/icon_jual.png) center center no-repeat;
	background-size: cover;
}
.classikonrusak {
	background: transparent url(../img/icon_rusak.png) center center no-repeat;
	background-size: cover;
}
.classikonwarning {
	background: transparent url(../img/icon_warning.png) center center no-repeat;
	background-size: cover;
}
.classikonrm {
	background: transparent url(../img/icon_rekammedis.png) center center no-repeat;
	background-size: cover;
}
.classikondaftar {
	background: transparent url(../img/icon_daftarperiksa.png) center center no-repeat;
	background-size: cover;
}
.classikontutup {
	background: transparent url(../img/icon_close.png) center center no-repeat;
	background-size: cover;
}
.classikontransfer {
	background: transparent url(../img/icon_transfer.png) center center no-repeat;
	background-size: cover;
}

.classikonfreerole {
	background: transparent url(../img/icon_freerole.png) center center no-repeat;
	background-size: cover;
}
.classikonadmin {
	background: transparent url(../img/icon_admin.png) center center no-repeat;
	background-size: cover;
}
.classikonparamedis {
	background: transparent url(../img/icon_paramedis.png) center center no-repeat;
	background-size: cover;
}
.classikondokter {
	background: transparent url(../img/icon_dokter.png) center center no-repeat;
	background-size: cover;
}
.classikonentri {
	background: transparent url(../img/icon_entri.png) center center no-repeat;
	background-size: cover;
}
.classikonupload {
	background: transparent url(../img/icon_upload.png) center center no-repeat;
	background-size: cover;
}
.classikonkamera {
	background: transparent url(../img/icon_kamera.png) center center no-repeat;
	background-size: cover;
}
.classikonfoto {
	background: transparent url(../img/icon_foto.png) center center no-repeat;
	background-size: cover;
}
.classikonarrived {
	background: transparent url(../img/icon_arrived.png) center center no-repeat;
	background-size: cover;
}

.classfakesubheader {
	margin:-15px -45px 10px -45px;/**/
	width:auto !important;
	padding: 15px;
	background-color: white;
}

.classulreguler{
	border: none;
	margin:0;

	/*border:1px solid red;/**/
}
.classlireguler{
	padding: 3px;
	list-style: square inside !important;
}
.classblinking {
  -webkit-animation: blink 1s step-end infinite;
  -moz-animation: blink 1s step-end infinite;
  -o-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}

@-webkit-keyframes blink {
  67% { opacity: 0 }
}

@-moz-keyframes blink {
  67% { opacity: 0 }
}

@-o-keyframes blink {
  67% { opacity: 0 }
}

@keyframes blink {
  67% { opacity: 0 }
}

.classimgcircle {
	position: relative;
	overflow: hidden;
	border-radius: 50%;
}
img.classprofilepic {
	position: absolute;
	top: 50%;
	left:50%;
	height: 100%;
	width: auto;
  	transform: translate(-50%,-50%);
}
img.classimgcircle {
	border-radius: 50%;
}
img.classgrayscale {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}
.classposbottomcenter {
	position: absolute;
	bottom:-5px;
	left:50%;
	transform: translate(-50%,0);
}
.classsidepopright {
	position:fixed !important;
	top:0 !important;
	right:0 !important;
	z-index: 1060;
	width: 90%;
	height: 100%;
	background-color:transparent !important;
}
.classinside {
	margin: 5px 0;
	border: 15px solid;
	border-color: rgba(#000000,0.1);
	background-color: rgba(white,0.85);
	height: calc(100% - 10px) !important;
	border-radius: 10px;
}
a:hover.classnounderline {
	text-decoration: none !important;
}