@media (min-width: 768px) {
.classsidepopright {
	width: 50% !important;
	border-color: red;
}
}

@media (min-width: 992px) {
.classsidepopright {
	width: 30% !important;
}
}